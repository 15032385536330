// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/apps/web/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/apps/web/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-expertise-tsx": () => import("/opt/build/repo/apps/web/src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/apps/web/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-development-projects-docdok-tsx": () => import("/opt/build/repo/apps/web/src/pages/mobile-development-projects/docdok.tsx" /* webpackChunkName: "component---src-pages-mobile-development-projects-docdok-tsx" */),
  "component---src-pages-mobile-development-projects-index-tsx": () => import("/opt/build/repo/apps/web/src/pages/mobile-development-projects/index.tsx" /* webpackChunkName: "component---src-pages-mobile-development-projects-index-tsx" */),
  "component---src-pages-mobile-development-projects-opensource-tsx": () => import("/opt/build/repo/apps/web/src/pages/mobile-development-projects/opensource.tsx" /* webpackChunkName: "component---src-pages-mobile-development-projects-opensource-tsx" */),
  "component---src-pages-mobile-development-projects-papott-tsx": () => import("/opt/build/repo/apps/web/src/pages/mobile-development-projects/papott.tsx" /* webpackChunkName: "component---src-pages-mobile-development-projects-papott-tsx" */),
  "component---src-pages-mobile-development-projects-sneat-tsx": () => import("/opt/build/repo/apps/web/src/pages/mobile-development-projects/sneat.tsx" /* webpackChunkName: "component---src-pages-mobile-development-projects-sneat-tsx" */),
  "component---src-pages-react-native-mobile-developer-tsx": () => import("/opt/build/repo/apps/web/src/pages/react-native-mobile-developer.tsx" /* webpackChunkName: "component---src-pages-react-native-mobile-developer-tsx" */)
}

